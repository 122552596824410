<template>
   <div id="loader-wrapper">
        <div id="loader"></div>
        <div class="loader-section section-left"></div>
        <div class="loader-section section-right"></div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>